import React from "react"
import { useNavigate } from "react-router-dom"

const ExhasutedPricing = (props) => {
    const navigate = useNavigate()
  return (
    <div className="fixed  z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto flex items-center justify-center h-full w-full px-4">
        <div className="text-center  rounded-xl bg-white p-4 border-2">
            <p className="font-poppins">Subscribe to a Paid plan to practise more Interviews. Write to us at info@interviewvision.com for details.</p>
            <button className="bg-yellow-500 w-full py-2 rounded-xl font-bold my-3 font-poppins" onClick={()=>{
                navigate('/home')
                window.location.reload()
        }}>Okay</button>
        </div>
    </div>
  )
}

export default ExhasutedPricing